<template>
    <div class="my_treat_page">
        <van-sticky :offset-top="0">
            <div class="page_top" style="background-color: #FAFAFA;">
                <van-search class="search_div" input-align="center" background="#fff" left-icon="none"   @search="onSearch(keyword)" v-model="keyword" placeholder="请输入会员/家属姓名" />
                

            </div>
        </van-sticky>
        <div class="treat_main_list"  >
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            v-if="otherList.length > 0"
            :offset="30"
            :immediate-check="false"
            @load="getOther"
            >
            <div class="one"  v-for="(item,index) in otherList" :key="index">
                <div class="name_appointment">
                    <div class="left">{{item.name}}</div>
                    <div class="right">
                        <div class="detail_info" @click="medical(item.customerid,item.userid)">
                            <span>患者病历</span>
                            <i class="icon iconfont iconsanjiaoyou"></i>
                        </div>
                    </div>
                </div>
                <div class="sex_age_phone">
                    <span>{{item.sex }} &nbsp;{{item.age}}岁</span>
                    <span v-if="item.phone">{{(item.phone).replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")}}</span>
                </div>
                <div class="title_top" v-for="childid in item.projectlist" :key="childid.projectcode">
                    <div class="left">{{childid.projectname}}</div>
                    <div class="right" style="font-size: 12px;">剩余次数： <span style="color: #EE0A23;">{{childid.surplusnum}}</span><span>（订购次数：{{+childid.execnum + +childid.surplusnum}}）</span></div>
                </div>

                <div style="display: flex;justify-content: center;">
                    <div @click="chooseOther(item)" style="background-color: #CF8C57;width: 100px;height: 38px;text-align: center;line-height: 38px;color: #fff;border-radius: 21px;">选择</div>
                </div>
            </div>
        </van-list>
        <div class="has_no_data" v-else>
            当前无理疗数据
        </div>
        </div>
        
    </div>
</template>
<script>
import { wxtriageprojectqry } from '@/api/medical'
import { mapGetters } from 'vuex'
import { Toast,Dialog } from 'vant'
export default{
    computed: {
            ...mapGetters([
                'username','dispname','clinicid','isrefresh'
            ]),
        },
    data(){
        return{
            keyword:'',
            treat:'',
            otherList:[],
            loading: false,
            finished: false,
            page:0,
        }
    },

    activated(){
        if(this.isrefresh == '1'){
            this.page = 0;
            this.keyword = '';
            this.otherList = [];
            this.getOther()
        }
    },
    beforeRouteEnter(to, from, next) {
        console.log(to,'to',from)
        
        // from.meta.keepAlive = true
        localStorage.removeItem('otherdata')
        next()
    },
    methods:{
        // 他人抵扣
        getOther(){
            this.page += 1
            let data = {
                username: this.username,
                projectcode:'',
                keyword:this.keyword,
                userid:this.$route.query.userid,
                customerid:this.$route.query.customerid,
                qrytype:'1',
                qrypage:this.page,
            }
            wxtriageprojectqry(data).then(response =>{ 
                this.loading = false
                if(response.response_body.count){

                    this.otherList =[...this.otherList, ...response.response_body.list] || []
                    if(this.page >= response.response_body.pages){
                        
                        this.finished = true
                    }
                }else{
                    this.finished = true
                    this.otherList = []
                }
            })
        },
        onSearch(key){
            this.page = 0
            this.otherList = []
            this.finished = false
            this.loading = false
            this.keyword = key;
            this.getOther()

        },
        chooseOther(item){
            let that = this
            Dialog.confirm({
                title: '提示',
                message: `是否选择【${item.name}】患者进行扣减`,
                confirmButtonColor:'#CF8C57',
            }).then(() => {
                // on confirm
                if(localStorage.getItem('familya') != 'undefined'){
                    let arr = JSON.parse(localStorage.getItem('familya'))
                    if(arr.filter(val => val.userid == item.userid && val.customerid == item.customerid).length > 0){
                        Toast(`当前【${item.name}】的账号已存在`)
                        return
                    }
                }
                
                localStorage.setItem('otherdata',JSON.stringify(item))
                that.$router.go(-1)
            })
            .catch(() => {
                // on cancel
            });
           
        },
        medical(customerid,userid) {
            this.$store.dispatch('changeIsRefresh', '1')
            this.$router.push({
                path: '/medicalPage',
                query: {
                    userid,
                    customerid,
                }
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.my_treat_page{
    margin-bottom: 0;
    min-height: 100vh;
    .page_top{
        background-color: unset;
    }
    .date_choose{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        .page_top .date_choose .date_info{
            color: #ccc;
        }
    }
}
.van-search__content{
    background-color: #fff;
}
.page_top .search_div{
    width:100%;
}
.van-search__content{
    padding-left: unset;
}
.iconarrow-bottom-filling{
    width: 0;
    height: 0;
    border-top: 5px solid #ccc;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}
.bai {
        background-color: #ffffff;
    }
    .btnimg{
        width: 50px;
        height: 50px;
        position: fixed;
        right: 10px;
        bottom: 200px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .treat_main_list{

        .one{
           background: linear-gradient( 180deg, rgba(207,140,87,0.2) 0%, rgba(255,249,244,0) 20%) !important;
       }
       .name_appointment .left{
             width: 170px;
            white-space: nowrap;      
            overflow: hidden;         
            text-overflow: ellipsis;
       }
    }
    
</style>