<template>
    <div class="my_treat_page">
        <div class="head">
            <div class="info" style="width: 55%;">
                <div class="text" style="font-size: 16px;">{{uname }}</div>
                <div  class="text">
                    <span>{{usex }}</span>
                    <span> {{uage}}岁</span>
                    <span>{{uphone}}</span>
                </div>
                
            </div>
            <div class="info" style="width: 50%;margin-left: 35px;">
                <div class="text" style="text-align: left;">诊所：{{ ClinicName }}</div>
                <div class="text">工号：{{ dispname }}</div>
            </div>
        </div>
        <div class="list">
            <div class="list_top">
                <div :class="{user:true,active:item.status}" v-for="item in family" @click="chooseUser(item)">
                    <div>
                        <div class="item">{{ item.name }}</div>
                        <div class="item">{{item.phone && (item.phone).replace(/(\d{3})\d{4}(\d{4})/, "$1****$2") || ''}}</div>
                    </div>
                </div>
            </div>
            
            <div style="display: flex;justify-content: center;" >
                <!-- $router.push({path:'/otherPay',query:{userid,customerid,}}) -->
                <div class="list_btn" @click="chooseOther()">
                    他人抵扣
                </div>
            </div>
            
            <van-checkbox-group v-model="result" v-if="list.length > 0">
                <div class="list_item"  v-for="item in list" :key="item.projectcode" >
                    <div style="display: flex;justify-content: space-between;margin-bottom: 10px;">
                        <div class="tream">
                            <van-checkbox  checked-color="#CF8C57" :name="item">
                                <div class="text">{{ item.projectname }}</div>
                            </van-checkbox>
                        </div>
                        <div class="text" style="font-size: 14px;font-weight: 400;">剩余次数：<span style="color: #EE0A23;">{{ item.surplusnum}}</span></div>
                    </div>
                    <van-cell :border="false"  title="执行医生：" @click="chooseDoctor(item)" is-link :value="item.doctor" />
                    <van-cell  title="执行诊室：" @click="chooseRoom(item)" is-link :value="item.room" />
                </div>

                
            </van-checkbox-group>
            <div class="has_no_data" style="margin-top: 40px;" v-else>
                当前无可分诊的理疗项目
            </div>

        </div>

        <div style="position: fixed;left: 0;right: 0;bottom: 20px;margin: 0 35px;">
            <van-button color="#CF8C57" round @click="assignTreat" size="large">确认分诊</van-button> 
        </div>


        <van-popup v-model="showPicker" round position="bottom">
            <van-picker
                title="请选择医生"
                show-toolbar
                :columns="doctorList"
                :default-index="doctorList.findIndex(item => item.username == username)" 
                @cancel="showPicker = false"
                @confirm="onConfirmDoctor"
            ><template #option="item">{{ item.doctor }}</template>
        </van-picker>
        </van-popup>
        <van-popup v-model="showPicker2" round position="bottom">
            <van-picker
            title="请选择诊室"
            show-toolbar
            
            :columns="roomList"
            @confirm="onConfirmRoom"
            @cancel="showPicker2 = false"
        ><template #option="item">{{ item.roomname }}</template></van-picker>
        </van-popup>
        <!-- <van-popup v-model="showPay" closeable round position="bottom" :style="{height:'90%'}">
            <OtherPay></OtherPay>
        </van-popup> -->
        <van-dialog v-model="shownum" title="获取验证码" show-cancel-button confirm-button-color="#CF8C57" :before-close="close">
            <div style="padding: 5px;margin-bottom: 20px;">
                <van-field v-model="tel" label-width="50" type="tel" label="手机号" :center="true">
                    <template #left-icon>
                        <img src="@/assets/phone.png" alt="" style="height: 20px;width: 20px;">
                    </template>
                </van-field>
                <van-field
                    v-model="sms"
                    center
                    label-width="50"
                    clearable
                    label="验证码"
                    placeholder="请输入短信验证码"
                    >
                    <template #button>
                        <van-button size="small" color="#CF8C57" type="primary"  @click="sendCode" :disabled="countdown > 0">
                            {{ countdown > 0 ? `${countdown}秒后重新获取` : '获取验证码' }}
                        </van-button>
                    </template>
                    <template #left-icon>
                        <img src="@/assets/code.png" alt="" style="height: 20px;width: 20px;">
                    </template>
                </van-field>
            </div>
        </van-dialog>
    </div>
</template>
<script>
import { wxtreattriageopr ,wxtriageprojectqry,wxtriagedoctorroomqry,curuserqrytreattriage,wxtreatuserphonecaptchano} from '@/api/medical'
import { mapGetters } from 'vuex'
import { Toast,Dialog } from 'vant';
import OtherPay from '../otherPay/index.vue'
export default{
    computed: {
            ...mapGetters([
                'username','dispname','clinicid'
            ]),
        },
        components:{
            OtherPay
        },
    data(){
        return{
            tel:'',//手机号
            list:[],
            userList:[],
            result:[],
            showPicker:false,
            showPicker2:false,
            treatitem:{},
            ClinicName:'',
            doctorList:[],
            roomList:[],
            family:[ ],
            radio:'',
            other:{},
            userprojectlist:[],
            len:'',
            shownum:false,
            sms:'',
            phone:'',
            countdown: 0,
            userid:this.$route.query.userid || localStorage.getItem('userida'),
            customerid:this.$route.query.customerid || localStorage.getItem('customerida'),
            usecustomerid:this.$route.query.customerid || localStorage.getItem('customerida'),
            useuserid:this.$route.query.userid || localStorage.getItem('userida'),
            uname:this.$route.query.name || localStorage.getItem('namea'),
            uage:this.$route.query.age|| localStorage.getItem('agea'),
            usex:this.$route.query.sex || localStorage.getItem('sexa'),
            uphone:this.$route.query.phone || localStorage.getItem('phonea'),
            showPay:false,
            intervalId:null,
            historynum:null,
        }
    },
    mounted () {
        this.$store.dispatch('hideOrShowNav', false)  
        
    },
    beforeRouteEnter(to, from, next) {
      
        if(from.path == '/otherPay'){
            next(vm =>{
                vm.uname = localStorage.getItem('namea')
                vm.uage = localStorage.getItem('agea')
                vm.usex = localStorage.getItem('sexa')
                vm.uphone = localStorage.getItem('phonea')
                vm.userid = localStorage.getItem('userida')
                vm.customerid = localStorage.getItem('customerida')
                // vm.userprojectlist = JSON.parse(localStorage.getItem('userprojectlist'))
                vm.historynum = localStorage.getItem('historynum')
                vm.result = []

                if(localStorage.getItem('familya') != 'undefined'){
                    vm.family = JSON.parse(localStorage.getItem('familya'))
                    vm.len = vm.family.length
                   
                }else{
             
                    vm.len = 0
                    vm.family = []
                }
               
                // to.meta.keepAlive = true
                vm.ClinicName = sessionStorage.ClinicName
                // vm.getuser()
                
                if(localStorage.getItem('otherdata')){

                    vm.onConfirmOther(JSON.parse(localStorage.getItem('otherdata')|| ''))
                }
               
                
            })
        }else{
            next(vm =>{
               
                // to.meta.keepAlive = false
                vm.$store.dispatch('hideOrShowNav', false)  
                vm.uname = localStorage.getItem('namea')
                vm.uage = localStorage.getItem('agea')
                vm.usex = localStorage.getItem('sexa')
                vm.uphone = localStorage.getItem('phonea')
                vm.userid = localStorage.getItem('userida')
                vm.customerid = localStorage.getItem('customerida')
                // vm.userprojectlist = JSON.parse(localStorage.getItem('userprojectlist'))
                vm.historynum = localStorage.getItem('historynum')
                vm.result = []
                vm.getuser()
                   
            })
        }
    },
    beforeRouteLeave(to, from, next) {
   
        if(to.path == '/triage'){
            to.meta.keepAlive = true
         
            localStorage.removeItem('userida')
            localStorage.removeItem('customerida')
            localStorage.removeItem('namea')
            localStorage.removeItem('agea')
            localStorage.removeItem('sexa')
            localStorage.removeItem('phonea')
            localStorage.removeItem('familya')
            localStorage.removeItem('otherdata')
            // localStorage.removeItem('userprojectlist')
            localStorage.removeItem('historynum')
        }else if(to.path == '/otherPay'){
            to.meta.keepAlive = true
            localStorage.setItem('familya',JSON.stringify(this.family))
        }
        else if(to.path == '/'){
            from.meta.keepAlive = false
        }
        next()
    },
    created(){
        localStorage.setItem('userida', this.$route.query.userid);
        localStorage.setItem('customerida', this.$route.query.customerid);
        localStorage.setItem('namea', this.$route.query.name);
        localStorage.setItem('agea', this.$route.query.age);
        localStorage.setItem('sexa', this.$route.query.sex);
        localStorage.setItem('phonea', this.$route.query.phone);
        
  


        this.gettriageprojectqry(this.userid,this.customerid)
        this.getdoctorroomList()
        
        this.ClinicName = sessionStorage.ClinicName
    },
    methods:{
        close(action, done){

            if (action == 'confirm' && !this.sms.trim()) {
                Toast.fail('请输入验证码！')
                done(false)
            } else if(action == 'cancel'){
                this.countdown = 0;
                clearInterval(this.intervalId);
                this.intervalId = null
                
                done(true)
                
            }
            else {
                this.getTreat('3',this.sms.trim()).then(res=>{
                    if(res.response_head.retinfo.retcode == '0'){
                        done(true)
                    }else{
                        done(false)
                    }
                }).catch(err=>{
                   
                    Toast.fail(err.response_head.retinfo.retmsg)
                    done(true)
                })
                
            }
        },
        sendCode() {
            // 模拟发送验证码的操作
           
            let data = {
                username:this.username,
                userid:this.other.userid,
                customerid:this.other.customerid
            }
            
            wxtreatuserphonecaptchano(data).then(response =>{

                this.countdown = 60;
        
                // 开始倒计时
                this.intervalId = setInterval(() => {
                    if (this.countdown > 0) {
                    this.countdown -= 1;
                    } else {
                    clearInterval(this.intervalId);
                    }
                }, 1000);
                
                
            }).catch(()=>{
                Toast.fail('获取验证码失败！')
            })
        
            // 设置倒计时为60秒
            
        },
        chooseOther(){
            this.$store.dispatch('changeIsRefresh', '1')
            this.$router.push({path:'/otherPay',query:{userid:this.$route.query.userid,customerid:this.$route.query.customerid,}})
        },
        chooseUser(item){
            this.list = []
            this.result = []
            let userid = this.$route.query.userid
            let customerid = this.$route.query.customerid
            this.tel =item.phone && (item.phone).replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
            // 用id判断
            this.family.map((val,index) => {
                if(item.userid == val.userid && item.customerid == val.customerid){
                    val.status = !val.status
                }else{
                    val.status = false
                }
                if(val.status ){
                    this.other = item
                    
                    this.useuserid = val.userid
                    this.usecustomerid = val.customerid
                    userid = val.userid
                    customerid = val.customerid
                }
            })
            this.gettriageprojectqry(userid,customerid)
        },
        // 查看可分诊用户
        getuser(){
            let data = {
                username: this.username,
                userid:this.userid,
                customerid:this.customerid,
            }
            curuserqrytreattriage(data).then(response =>{
                
                this.family = response.response_body.list?.map(item =>{
                    item.status = false
                    return item
                })
               
                localStorage.setItem('familya',JSON.stringify(this.family))
                
                if(this.family == undefined){

                    this.len = '0'
                    this.family = []
                }else{
                    
                    this.len = this.family.length
                }
                
            })
        },
        // 查询医生
        getdoctorroomList(){
            let data = {
                username: this.username,
                clinicid:sessionStorage.ClinicId
            }
            wxtriagedoctorroomqry(data).then(response =>{
               
                this.doctorList = response.response_body.doctorlist
                this.roomList = response.response_body.roomlist
            })
        },
        onConfirmOther(val){
            // this.result = []
            // this.showPicker3 = false
           
            if(this.len){
                this.family?.map((val,index) => {
                    val.status = false
                    
                })
            }
            
            this.list = val.projectlist
            this.other = val
            this.useuserid = val.userid
            this.usecustomerid = val.customerid
            this.tel =val.phone && (val.phone).replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
            
            
            val.status = true

            if(this.family.filter(item => val.userid == item.userid && val.customerid == item.customerid).length == 0){

                this.family.push(val)
            }
            localStorage.setItem('familya',JSON.stringify(this.family))
            // this.family.push(val)
            this.gettriageprojectqry(val.userid,val.customerid)
          
        },
        
        // 可分诊项目查询
        gettriageprojectqry(userid,customerid){
            let useuserid = ''
            let usecustomerid = ''
            if(this.userid == userid && this.customerid == customerid){
                 useuserid = ''
                 usecustomerid = ''
            }else{
                useuserid = this.$route.query.userid
                usecustomerid = this.$route.query.customerid
            }
      
            let data = {
                    username: this.username,
                    projectcode:'',
                    keyword:'',
                    userid,
                    customerid,
                    qrytype:'0',
                    qrypage:1,
                    useuserid,
                    usecustomerid,
                }
            wxtriageprojectqry(data).then(response =>{
                
                if(response.response_body.pages == 0){
                    this.userList = []
                    this.list = []
                }else{

                    if(userid == this.$route.query.userid && customerid == this.$route.query.customerid){
    
                        // this.userprojectlist = response.response_body.list[0].projectlist
                        this.historynum = response.response_body.list[0].historynum
                        // localStorage.setItem('userprojectlist',JSON.stringify(this.userprojectlist))
                        localStorage.setItem('historynum',this.historynum)
                    }
                    this.userList = response.response_body.list || []
                    this.list = response.response_body.list[0].projectlist.filter(item => item.surplusnum > 0) || []
                    this.userprojectlist = response.response_body.list[0].projectlist
                    this.list.forEach(item=>{
                        item.doctor = this.dispname,
                        item.room = '请选择诊室',
                        item.roomid = '',
                        item.username = this.username
                    })
                }
               
            })
        },
        assignTreat(){

            if(this.result.length == 0){
                Toast('请选择分诊项目！')
                return
            }

            // 消费患者
            let repeatlist = []
            repeatlist = this.userprojectlist.filter(item => Number(item.curexecnum) > 0)
           
            // 选中项目
            let result = []
            this.result.map(item => item.projectcode).forEach((item,index) =>{
                if(repeatlist.map(item => item.projectcode).includes(item)){
                    result.push(this.result[index])
                }
            })

          
            let msg = '';
            if(repeatlist.length){
                if(result.length == 0){
                    msg =  `该患者当天有执行其他项目：${(repeatlist.map(item =>  item.projectname)).join('、')}，您确认是否再次分诊？`
                }else if(result.length){
                    msg = `该患者当天有执行项目：${(result.map(item =>  item.projectname)).join('、')}，您确认是否再次分诊？`
                }
            }else{
               if(+this.historynum > 0){
                msg = `该患者有历史未完成的项目，您确认是否再次分诊?`
               }else{
                msg = `您确认是否提交此分诊数据？`
               }
            }

            Dialog.confirm({
                title: '提示',
                message: msg,
                confirmButtonColor:'#CF8C57',
            })
            .then(() => {
                // on confirm
                this.treatJudge()
            })
            .catch(() => {
                // on cancel
            });
		

                     
        },
        treatJudge(){
            let selectoption = '0';
            if(this.userid == this.useuserid) {
                if(this.customerid == this.usecustomerid) {
                    this.getTreat(selectoption)
                } else {
                    if(this.usecustomerid == 0) {
                        selectoption = '1'
                        this.getTreat(selectoption)
                    } else {
                        selectoption = '2'
                        this.getTreat(selectoption)
                    }
                }
            } else {
                this.sms = ''
                this.shownum = true
            }  
        },
        getTreat(selectoption,captchano){
            
            var res = new Promise((resolve,reject)=>{

            
           
                let project = []
                this.result.forEach(item => project.push(
                    {
                            projectname:item.projectname,
                            projectcode:item.projectcode,
                            doctor:item.username,
                            room:item.roomid,
                            num:'1',
                        }
                ))

                let data = {
                    username:this.username,
                    projectlist:project,
                    selectoption,
                    userid:this.userid,
                    customerid:this.customerid,
                    useuserid:this.useuserid,
                    usecustomerid:this.usecustomerid,
                    captchano,
                    execclinicid:sessionStorage.ClinicId,
                }

                wxtreattriageopr(data).then(response =>{
                    resolve(response)
                    this.$store.dispatch('changeIsRefresh', '1')
                    Toast({message:'分诊成功！',onClose:()=>{
                        // 跳转页面
                        // location.reload()
                        this.$router.push('/treatPage')
                    }
                        
                    })
                    
                })
                .catch((err) => {
                    // on cancel
                    resolve(err)
                    Toast.fail(err.response_head.retinfo.retmsg)
                });
            })
        return res 
        },
        chooseDoctor(item){
            this.showPicker = true
            this.treatitem = item

        },
        chooseRoom(item){

            this.showPicker2 = true
            this.treatitem = item
        },
        onConfirmDoctor(val){

            this.treatitem.doctor = val.doctor
            
            this.treatitem.username = val.username

            this.showPicker = false
        },
        onConfirmRoom(val){
            this.treatitem.room = val.roomname
            this.treatitem.roomid = val.roomid
            this.showPicker2 = false
        }
    }
}
</script>
<style lang="scss" scoped>
.my_treat_page{
    margin-bottom: 0;
    min-height: 100vh;
    background-color: #F6F6F4;
    .head{
        background-color: #CF8C57;
        border-radius: 10px;
        color: #fff;
        font-size: 12px;
        padding: 15px;
        display: flex;
        align-items: center;
        font-size: 12px;
        .text{
            margin-bottom: 10px;
            span{
                margin-right: 8px;
            }
        }
    }
    .list{
        background-color: #fff;
        border-radius: 10px;
        margin-top: -15px;
        padding: 15px 12px;
        margin-bottom: 80px;
        .list_top{
            
            display: flex;

                flex-wrap: wrap;
            .user{
                width: 95px;
                height: 45px;
                padding: 5px;
                text-align: center;
                background-color: #F5F5F5;
                border-radius: 8px;
                margin-right: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                margin-bottom: 8px;
                
                .item{
                    width: 80px;
                    white-space: nowrap;      
                    overflow: hidden;         
                    text-overflow: ellipsis;
                }
                
            }
            .active{
                background: rgba(207,140,87,0.2);
                border-radius: 8px;
                border: 1px solid #CF8C57;
            }
        }
        .list_btn{
            width: 140px;
            height: 35px;
            line-height: 35px;
            border-radius: 17.5px;
            background-color: #CF8C57;
            font-size: 16px;
            color: #fff;
            text-align: center;
            margin-bottom: 10px;
        }
        .list_item{
            background-color: #F5F5F5;
            border-radius: 10px;
            padding: 10px;
            margin-bottom: 10px;

            .tream{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .text{
                    font-weight: 600;
                    font-size: 16px;
                    color: #323333;
                    line-height: 22px;
                    text-align: left;
                }
            }
        }
    }
    .van-cell{
        padding:0 5px 5px;
        background-color: #F5F5F5;
       
        ::v-deep .van-icon {
            display: flex;
            align-items: center;
        }
    }

    
}
::v-deep .van-checkbox__label{
    width: 100% !important;
}
.my_treat_page .van-cell {
    ::v-deep .van-field{

        background-color: #fff;
    }
}
.van-dialog{

    ::v-deep .van-dialog__header{
        padding: 10px 0  25px !important;
    }
    ::v-deep .van-field{
        background-color: #fff;
        margin-bottom: 5px;
    }
}
</style>